import React from "react";
import "./app.scss";
import yellowstroke from "../../../images/mlp/yellow-underline.webp";

const Card = (props) => {

  return (
    <div className="sidebar-card">
      <div className="sidebar-title">{props.title}
        <span className="text-strokes">
          <img
            src={yellowstroke}
            alt="strokes"
          />
        </span>
      </div>
      <div className="sidebar-trademark">
      1600+ students have secured a marketing job without an MBA.
      </div>
      <div className="sidebar-about">
        {/* {props.about} */}
        <ul className="feature-list">
          <li>16 weeks, online, full-time program</li>
          <li>Get hired in performance marketing, content, social media, and more</li>
          <li>Highest CTC: &#8377;9.2 LPA</li>
          <li>World’s largest agencies hire from us again and again</li>
          <li>94% placement rate</li>
        </ul>
      </div>
    
      <div className="sidebar-cta">
        <a href={`https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/?utm_medium=blog&utm_source=side_bar&utm_campaign=seo&utm_content=mlp_blog`} className="becomeMarketer" target="_blank" rel="noreferrer">
        Become A Marketer Today
        </a>
      </div>
    </div>
  );
};

const SideBlog = (props) => {
  const { link } = props;

  const shareLinks = [
    {
      id: "1",
      logo: "facebook.svg",
      url: `https://www.facebook.com/sharer.php?u=${link}`,
    },
    {
      id: "2",
      logo: "twiiter.svg",
      url: `https://twitter.com/intent/tweet?url=${link}`,
    },
    {
      id: "3",
      logo: "linkedin.svg",
      url: `https://www.linkedin.com/shareArticle?mini=true&amp;url=${link}`,
    },

 
  ];

  return (
    <div className="blog-layout-container">
      {/* <Header /> */}

      <div className="blog-layout-wrapper container">
        <div
          className={
            "blog-content " + (props.hideSidebar ? "blog-content-center" : "")
          }
        >
          {props.children}
         
        </div>

        {!props.hideSidebar && (
          <div className="sidebar ml-2">
            <Card
              title="Marketing Launchpad"
              about="This is 20 week online full-time program for which we select, train and invest in high potential graduates to help them start a fantastic career in marketing. The best part? You pay ONLY when you land a job with a minimum CTC of INR 4.5 lakhs."
              link={`https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/${link}`}
            />
 
          </div>
        )}
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default SideBlog;
